// Auth
export const ATTEMPT_LOGOUT = 'ATTEMPT_LOGOUT';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_AUTH = 'SET_AUTH';
export const SET_USER = 'SET_USER';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ADD_MEMBER = 'CREATE_ACCOUNT_ADD_MEMBER';
export const CREATE_ACCOUNT_DELETE_MEMBER = 'CREATE_ACCOUNT_DELETE_MEMBER';
export const CREATE_ACCOUNT_UPDATE_FORM = 'CREATE_ACCOUNT_UPDATE_FORM';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const DELETE_BUSINESS = 'DELETE_BUSINESS';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const FETCH_ACCOUNT_ACTIVITY = 'FETCH_ACCOUNT_ACTIVITY';
export const FETCH_ACCOUNT_ACTIVITY_SUCCESS = 'FETCH_ACCOUNT_ACTIVITY_SUCCESS';
export const FETCH_BUSINESSES = 'FETCH_BUSINESSES';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const FETCH_USER_ACCOUNTS = 'FETCH_USER_ACCOUNTS';
export const FETCH_USER_ACCOUNTS_SUCCESS = 'FETCH_USER_ACCOUNTS_SUCCESS';

export const CHECK_USERNAME = 'CHECK_USERNAME';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_UPDATE_FORM = 'CREATE_USER_UPDATE_FORM';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const SET_PRICE = 'SET_PRICE';

export const SET_LOADING = 'SET_LOADING';
export const UPDATE_LOADING = 'UPDATE_LOADING';

export const GET_SIGNED_URL = 'GET_SIGNED_URL';
export const GET_SIGNED_URL_SUCCESS = 'GET_SIGNED_URL_SUCCESS';

// Modal
export const CLEAR_MODAL = 'CLEAR_MODAL';
export const SET_MODAL = 'SET_MODAL';

// Nav
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const SET_REDIRECT = 'SET_REDIRECT';

// Payment Method
export const FETCH_PAYMENT_METHOD = 'FETCH_PAYMENT_METHOD';
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';

// Subscription
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
