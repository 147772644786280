import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
 accountImage: {
  height: '120px',
  width: '120px',
 },
 boxTitle: {
  color: '#A0A0A0',
  fontSize: 16,
  fontWeight: '500',
 },
 businessTitle: {
  color: '#484040',
  fontSize: 24,
  fontWeight: '700',
 },
 businessVicinity: {
  color: '#484040',
  fontSize: 16,
 },
});

export default useStyles;
