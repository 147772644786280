import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
 title: {
  color: '#A0A0A0',
  fontSize: 24,
 },
});

export default useStyles;
